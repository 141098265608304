import React from 'react'
import { Layout, FlexRow, Parallax } from '@components/layout'
import { ProductItem } from '@components/ecommerce'

import {
cart__wrapp
} from './styles/simpleCart.module.scss'


const renderWithAllProps = (Comp) => (entry, key) =>{
	return (<Comp {...entry} key={key}/>)
}

const SingleCategory = ({ pageContext }) => {
	const { products, posts, page, categories } = pageContext
	console.log(pageContext)
	return (
		<Layout {...page}
		>
			<Parallax height={400} image={page.featuredImage}>
			<h1>Zbiory zadań</h1>
			</Parallax>

			<div className={cart__wrapp}>			
				{products.map(renderWithAllProps(ProductItem))}
				</div>
			
		</Layout>
	)
}

export default SingleCategory
